import { motion } from "framer-motion";
import { urlFor, client } from "../../client";
import React, { useState, useEffect } from "react";
import "./WorkingPlace.scss";
const WorkingPlace = () => {
  const [works, setWorks] = useState([]);

  useEffect(() => {
    const query = '*[_type == "workingfor"]';

    client.fetch(query).then((data) => {
      setWorks(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text App__profile-header" id="work">
        Working <span>Places</span> <br />
      </h2>

      <div className="app__workat">
        {works.map((work, index) => (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1.5, type: "tween" }}
            className="app__workat-items"
            key={work.title + index}
          >
            <img src={urlFor(work.imgUrl)} alt={work.title} />
            <div className="app__workat-about-container">
              <p className="app__workat-desc" style={{ marginTop: 10 }}>
                {work.title}
              </p>
              {/* <p className="app__workat-desc" style={{ marginTop: 10 }}>
                {work.date}
              </p> */}

              {/* <p className="app__workat-desc" style={{ marginTop: 10 }}>
                <span className="app__workat-sp">{work.desc}</span>
                {work.description}
              </p>
              <p className="app__workat-desc" style={{ marginTop: 10 }}>
                <span className="app__workat-sp">{work.addressInfo}</span>
                {work.address}
              </p>
              <p className="app__workat-desc" style={{ marginTop: 10 }}>
                <span className="app__workat-sp">{work.mobileInfo}</span>
                {work.mobile}
              </p> */}
              {/* <p className="app__workat-desc" style={{ marginTop: 10 }}>
                <span className="app__workat-sp">{work.pageInfo}</span>
                <a
                  className="app__workat-link"
                  href={work.page}
                  target="_blank"
                  rel="noreferrer"
                >
                  من هنا
                </a>
              </p>
              <p className="app__workat-desc" style={{ marginTop: 10 }}>
                <span className="app__workat-sp">{work.offerInfo}</span>
                {work.info}
              </p> */}
            </div>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default WorkingPlace;
