import React from "react";
import Hero from "../../components/Hero/Hero";
import About from "../../components/About/About";
import Certificate from "../../components/Certificate/Certificate";
import WhyMe from "../../components/WhyMe/WhyMe";
import Offer from "../../components/Offer/Offer";
import WorkingPlace from "../../components/WorkingPlace/WorkingPlace";
import Prices from "../../components/Prices/Prices";
import Testimonials from "../../components/Testimonials/Testimonials";
import Brand from "../../components/Brand/Brand";
import Contact from "../../components/Contact/Contact";
import FeedBack from "../../components/FeedBack/FeedBack";
import Awards from "../../components/Awards/Awards";
import CustomizedAccordions from "../../components/Accordion/Accordion";

const Home = () => {
  return (
    <div>
      <Hero />
      <About />
      <Certificate />
      <WhyMe />
      <Offer />
      <WorkingPlace />
      <Awards />
      <Prices />
      <Testimonials />

      <Brand />

      {/* <FeedBack /> */}
      <Contact />
    </div>
  );
};

export default Home;
