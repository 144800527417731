import { motion } from "framer-motion";
import { urlFor, client } from "../../client";
import React, { useState, useEffect } from "react";
import siqnture from "../../assets/image/sig.png";
import CptLogo from "../../assets/image/cpt-logo.png";
import Elite from "../../assets/image/elite.png";
import "./About.scss";

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text App__profile-header" id="about">
        Who is <span>Ahmed Rahmou</span> <br />
      </h2>

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1.5, type: "tween" }}
            className="app__profile-items"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <div className="app__profile-about-container">
              <h2 className="app__profile-title" style={{ marginTop: 20 }}>
                {about.title}
              </h2>
              <p className="app__profile-desc" style={{ marginTop: 10 }}>
                {about.description}
              </p>
              <div className="app__profile-sig-container">
                <img className="app__profile-sig" src={siqnture} alt="Rahmou" />
                <img
                  className="app__profile-sig-1"
                  src={CptLogo}
                  alt="Certified Certificate Personal Trainer"
                />
                <img
                  className="app__profile-sig-1"
                  src={Elite}
                  alt="Certified Certificate Elite Trainer"
                />
              </div>
              <div className="app__profile-resume-container-1">
                <a href="/AhmedResume.pdf" target="_blank" rel="noreferrer">
                  <button className="app__profile-resume-btn">
                    Download Rahmou's Resume
                  </button>
                </a>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default About;
